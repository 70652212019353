// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chapeco-js": () => import("./../../src/pages/chapeco.js" /* webpackChunkName: "component---src-pages-chapeco-js" */),
  "component---src-pages-coredec-chapeco-js": () => import("./../../src/pages/coredec-chapeco.js" /* webpackChunkName: "component---src-pages-coredec-chapeco-js" */),
  "component---src-pages-coredec-maravilha-js": () => import("./../../src/pages/coredec-maravilha.js" /* webpackChunkName: "component---src-pages-coredec-maravilha-js" */),
  "component---src-pages-coredec-sao-miguel-do-oeste-js": () => import("./../../src/pages/coredec-sao-miguel-do-oeste.js" /* webpackChunkName: "component---src-pages-coredec-sao-miguel-do-oeste-js" */),
  "component---src-pages-coredec-xanxere-js": () => import("./../../src/pages/coredec-xanxere.js" /* webpackChunkName: "component---src-pages-coredec-xanxere-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-obrigado-js": () => import("./../../src/pages/obrigado.js" /* webpackChunkName: "component---src-pages-obrigado-js" */),
  "component---src-templates-panel-city-js": () => import("./../../src/templates/panel-city.js" /* webpackChunkName: "component---src-templates-panel-city-js" */)
}

