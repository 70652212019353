module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-126985695-3","head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"cookieDomain":"painelcoronavirus.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Painel Coronavirus","short_name":"Painel Coronavirus","start_url":"/","background_color":"#f7f0eb","theme_color":"#0b1a32","display":"minimal-ui","icon":"src/images/covid-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7825f66fd8a613c670f4ef60b43056ca"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
